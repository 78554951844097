/**
 * Let op! Deze is van de _NIEUWE_ PAGE BUILDER.
 */

import docReady from '../../../assets/js/docReady';

class NavigationComponent {
    constructor(element) {
        this.element = element; // The navigation component Dom element

        this.subNavDynamicHeight = 0;
        this.mainNavAnimationDuration = 320;
        this.subnavAnimationDuration = 180; // Value in MS for subnav slide animation
        this.overlayAnimationDuration = 320;
        this.open = false;
    }

    init() {
        this.element.classList.add('strapped');
        // Force override of css vars to ensure matching timing with js
        this.element.style.setProperty('--subnav-transition-duration', `${this.subnavAnimationDuration}ms`);
        this.element.style.setProperty('--main-nav-transition-duration', `${this.mainNavAnimationDuration}ms`);
        this.element.style.setProperty('--overlay-transition-duration', `${this.overlayAnimationDuration}ms`);

        this.elements = {
            overlayToggle: this.element.querySelector('#header-navigation-toggle'),
            overlayElement: this.element.querySelector('#nav-overlay-element'),
            movingNavContainer: this.element.querySelector('.dynamic-nav-container'),
            mainNav: this.element.querySelector('.main-nav'),
            headerNavigation: this.element.querySelector('#header-navigation'),
            mainLinks: [...this.element.querySelectorAll('.main-link.with-subnav')],
        };

        this.bootstrap();
    }

    bootstrap() {
        this.elements.overlayToggle.addEventListener('click', () => {
            this.toggleOverlay()
            if (this.open) {
                this.openMainNav();
            } else {
                this.closeMainNav();
                this.elements.headerNavigation.addEventListener('transitionend', (e) => {
                    if (e.propertyName !== 'height') return;
                    this.elements.headerNavigation.style.display = 'none';
                    this.elements.headerNavigation.ariaHidden = true;
                    document.documentElement.classList.remove('nav-overlay-active');
                }, { once: true });
            }
        })

        this.elements.mainLinks.forEach((link) => {
            link.addEventListener('click', () => {
                this.toggleSubnav(link);
            });
        });

        this.element.querySelectorAll('.to-main-link')
            .forEach((element) => {
                element.addEventListener('click', () => {
                    this.unToggleSubnav(element);
                });
            });

        this.elements.overlayElement.style = null;
    }
    toggleOverlay() {
        this.open = !this.open;

        if (this.open) {
            this.openOverlay();
        } else {
            this.closeOverlay();
        }
    }

    openOverlay() {
        this.element.classList.add('nav-toggled');
        document.documentElement.classList.add('nav-overlay-active');
        this.elements.overlayElement.classList.add('overlay-active');
    }
    closeOverlay() {
        this.element.classList.remove('nav-toggled');
        this.elements.overlayElement.classList.remove('overlay-active');
    }
    // Open the main navigation. After animations, remove display and class
    openMainNav() {
        this.logEvent('open-main-nav');

        this.elements.headerNavigation.style.display = null;
        this.elements.headerNavigation.ariaHidden = false;

        let newNavHeight;
        if (this.elements.movingNavContainer.classList.contains('subnav-active')) {
            newNavHeight = this.element.querySelector('.subnav-container.active').clientHeight;
        } else {
            newNavHeight = this.elements.mainNav.clientHeight;
        }

        this.setNavHeight(newNavHeight);
        requestAnimationFrame(() => {
            this.element.classList.add('nav-active');
        })

    }

    // Close the main navigation. After animations, remove display and class
    closeMainNav() {
        this.logEvent('close-main-nav');

        this.setNavHeight(0);
        this.element.classList.remove('nav-active');
    }

    // Slide the active sub nav into view. Set height of the container div because absolute positioning
    toggleSubnav(parentLink) {
        this.logEvent('open-subnav', { subnav: parentLink.querySelector('.label').innerHTML });

        this.elements.movingNavContainer.classList.add('subnav-active');
        const subnav = parentLink.nextElementSibling;
        subnav.style.display = null;
        subnav.ariaHidden = false;
        requestAnimationFrame(() => {
            subnav.classList.add('active');
        })

        this.setNavHeight(subnav.clientHeight);
        this.setMainLinkTabIndex(-1);
    }

    // Slide the nav (back) to main nav. After animations, remove display and class
    unToggleSubnav(childLink) {
        this.logEvent('close-subnav');

        this.elements.movingNavContainer.classList.remove('subnav-active');
        const subnav = childLink.closest('.subnav-container');
        this.setNavHeight(this.elements.mainNav.clientHeight);
        requestAnimationFrame(() => {
            subnav.classList.remove('active');

        })

        subnav.addEventListener('transitionend', (e) => {
            subnav.style.display = 'none';
            subnav.ariaHidden = true;
            this.setMainLinkTabIndex(0);
        }, { once: true });

    }

    // Sets the height variable to eiter:
    //      0 - nav is closed
    //      height of the main-nav - default state for pages that are not in child menu's
    //      height of ths active subnav - when opening subnav or default on pages that are in the child menu
    setNavHeight(newNavHeight) {
        this.subNavDynamicHeight = `${newNavHeight}px`;
        requestAnimationFrame(() => {
            this.element.style.setProperty('--dynamic-nav-height', this.subNavDynamicHeight);
        });
    }

    setMainLinkTabIndex(tabIndex) {
        this.elements.mainNav.querySelectorAll('.main-link')
            .forEach((link) => {
                link.tabIndex = tabIndex;
            });
    }

    // eslint-disable-next-line class-methods-use-this
    logEvent(event, eventData) {
        if (typeof window.bananas !== 'undefined') {
            window.bananas('event', `navigation.${event}`, eventData);
        }
    }
}

docReady(() => {
    const navigationComponent = document.querySelector('.navigation-component.header');
    if (navigationComponent && !navigationComponent.classList.contains('strapped')) {
        const component = new NavigationComponent(navigationComponent);
        component.init(navigationComponent);
    }
});
